import { Socnet } from '@/types';
import PhoneSVG from '@/svg/phone.svg';
import WhatsappSVG from '@/svg/whatsapp.svg';
import TelegramSVG from '@/svg/telegram.svg';
import { AnimatePresence, m, easeIn } from 'framer-motion';
import { useIsPhoneButtonPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { formatPhoneHref } from '@/utils/strings';
import Button from '@/components/shared/Button';
import Popup from '@/components/shared/Popup';
import { YD_NEW_ID } from '@/metrics/ym';

type Props = {
    phone: string;
    socnets: Socnet[];
};

export const PHONE_BUTTON_POPUP_NAME = 'phone-button';

const PhoneButton = ({ phone, socnets }: Props) => {
    const _socnets = socnets.filter(
        (item) => item.name.toLowerCase() === 'whatsapp' || item.name.toLowerCase() === 'telegram',
    );
    const isOpened = useIsPhoneButtonPopupOpenedValue();
    const { openPopup, closePopup } = useSetOpenedPopupsState();
    return (
        <>
            <button
                className="phone-button"
                onClick={() => {
                    openPopup(PHONE_BUTTON_POPUP_NAME);
                    window.ym?.(YD_NEW_ID, 'reachGoal', 'click_on_the_phone');
                }}
                aria-label="Посмотреть варианты связи"
            >
                <span className="phone-button__icon">
                    <PhoneSVG />
                </span>
            </button>
            <Popup name={PHONE_BUTTON_POPUP_NAME} className="phone-button-popup" overlay>
                <AnimatePresence>
                    {isOpened && (
                        <m.div
                            variants={{
                                visible: {
                                    transition: {
                                        staggerChildren: 0.07,
                                        duration: 0.4,
                                        ease: easeIn,
                                    },
                                },
                            }}
                            initial="hidden"
                            animate={isOpened ? 'visible' : 'hidden'}
                            className="phone-button-list"
                        >
                            <m.button
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                    },
                                    hidden: {
                                        opacity: 0,
                                        y: '100%',
                                    },
                                }}
                                className="phone-button-close phone-button-item"
                                onClick={() => {
                                    closePopup(PHONE_BUTTON_POPUP_NAME);
                                }}
                            >
                                <span className="phone-button-text text-xs">Позвонить</span>
                                <Button
                                    tag={'span'}
                                    geometryVariant="square-bracket"
                                    className="phone-button-close__icon"
                                >
                                    X
                                </Button>
                            </m.button>
                            <m.a
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                    },
                                    hidden: {
                                        opacity: 0,
                                        y: '100%',
                                    },
                                }}
                                className="phone-button-item"
                                href={`tel:${formatPhoneHref(phone)}`}
                                onClick={() => window.ym?.(YD_NEW_ID, 'reachGoal', 'call_a_consultant')}
                            >
                                <span className="phone-button-item__icon">
                                    <PhoneSVG />
                                </span>
                                <span className="phone-button-item__text text-xs">Позвонить консультанту</span>
                            </m.a>
                            {_socnets.map((item, i) => {
                                return (
                                    <m.a
                                        variants={{
                                            visible: {
                                                opacity: 1,
                                                y: 0,
                                            },
                                            hidden: {
                                                opacity: 0,
                                                y: '100%',
                                            },
                                        }}
                                        className="phone-button-item"
                                        key={i}
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <span className="phone-button-item__icon">
                                            {item.name.toLowerCase() === 'whatsapp' && <WhatsappSVG />}
                                            {item.name.toLowerCase() === 'telegram' && <TelegramSVG />}
                                        </span>
                                        <span className="phone-button-item__text text-xs">
                                            связаться через {item.name}
                                        </span>
                                    </m.a>
                                );
                            })}

                            <m.button
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                    },
                                    hidden: {
                                        opacity: 0,
                                        y: '100%',
                                    },
                                }}
                                className="phone-button-close-mobile phone-button-item"
                                onClick={() => closePopup(PHONE_BUTTON_POPUP_NAME)}
                            >
                                <span className="phone-button-text text-xs">отменить</span>
                            </m.button>
                        </m.div>
                    )}
                </AnimatePresence>
            </Popup>
        </>
    );
};

export default PhoneButton;
