import Button from '../Button/Button';
import CompareSVG from '@/svg/compare.svg';
import CompareEmptySVG from '@/svg/compare-empty.svg';
import { useCookies } from 'react-cookie';
import { addUniqueItemToArray } from '@/utils/arrays/add-unique-item-to-array';
import { ButtonHTMLAttributes, MouseEvent, useRef, useState } from 'react';
import classNames from 'classnames';
import { CATEGORIES_SLUGS, CategoryId } from '@/api/categories';
import { YD_NEW_ID } from '@/metrics/ym';
import { AnimatePresence, m } from 'framer-motion';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import Link from 'next/link';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'default' | 'bordered-light' | 'card';
    productId: number;
    categoryId: CategoryId;
};

export const COMPARE_COOKIES_NAME = 'compare';

const CompareButton = ({ variant = 'default', productId, categoryId, ...props }: Props) => {
    const currentCategoryCookiesName = `${COMPARE_COOKIES_NAME}-${categoryId}`;
    const [cookies, setCookie] = useCookies(
        Object.keys(CATEGORIES_SLUGS).map((item) => `${COMPARE_COOKIES_NAME}-${item}`),
    );
    const comparing = cookies[currentCategoryCookiesName]?.includes(productId);
    const [mediaQueryDevice] = useMediaQueryDeviceState();
    const [message, setMessage] = useState('');
    const timeout = useRef<NodeJS.Timeout>();

    const onClick = (event: MouseEvent) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            setMessage('');
        }

        event.preventDefault();
        if (comparing) {
            if (cookies[currentCategoryCookiesName]) {
                setCookie(
                    currentCategoryCookiesName,
                    (cookies[currentCategoryCookiesName] as number[]).filter((item) => item !== productId),
                );
            }
            setMessage('');
        } else {
            if (cookies[currentCategoryCookiesName]) {
                setCookie(
                    currentCategoryCookiesName,
                    addUniqueItemToArray(cookies[currentCategoryCookiesName], productId),
                );
            } else {
                setCookie(currentCategoryCookiesName, [productId]);
            }

            if (mediaQueryDevice === 'desktop') {
                setMessage('Добавлено в сравнение');
            }
            window.ym?.(YD_NEW_ID, 'reachGoal', 'add_to_the_comparison');
        }

        timeout.current = setTimeout(() => {
            setMessage('');
        }, 5000);
    };

    switch (variant) {
        case 'bordered-light':
            return (
                <Button
                    variant={variant}
                    onClick={onClick}
                    className={classNames(props.className, 'compare-card-left-button')}
                    aria-label={comparing ? 'Удалить из сравнения' : 'Добавить в сравнение'}
                >
                    {comparing ? 'Из сравнения' : 'Сравнить'}
                </Button>
            );
        case 'card':
            return (
                <div className="compare-wrapper">
                    <Button
                        icon={comparing ? <CompareSVG /> : <CompareEmptySVG />}
                        onClick={onClick}
                        className={classNames(props.className, 'favorite-button-default')}
                        aria-label={comparing ? 'Удалить из сравнения' : 'Добавить в сравнение'}
                    />
                    <AnimatePresence>
                        {message && message !== '' && (
                            <m.div
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                className="compare-message m-text-xs"
                            >
                                {message.split(' ').map((word, index, arr) =>
                                    index === arr.length - 1 ? (
                                        <Link key={index} href="/compare" className="compare-message__link">
                                            {word}
                                        </Link>
                                    ) : index === 1 ? (
                                        `${word}\u00A0`
                                    ) : (
                                        `${word} `
                                    ),
                                )}
                            </m.div>
                        )}
                    </AnimatePresence>
                </div>
            );
        default:
            return (
                <Button
                    icon={comparing ? <CompareSVG /> : <CompareEmptySVG />}
                    onClick={onClick}
                    className={classNames(props.className, 'favorite-button-default')}
                    aria-label={comparing ? 'Удалить из сравнения' : 'Добавить в сравнение'}
                />
            );
    }
};

export default CompareButton;
