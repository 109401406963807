import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import classNames from 'classnames';
import { AnimatePresence, m } from 'framer-motion';

export interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    label?: string | null;
    message?: string | null;
    valid?: boolean;
    icon?: ReactNode;
    variant?: 'default' | 'white';
    tag?: keyof JSX.IntrinsicElements;
    inputClass?: string;
    showLabel?: boolean;
    prefix?: string;
    suffix?: string;
}

const Input = forwardRef<HTMLElement, Props>(
    (
        {
            id,
            label,
            valid = true,
            message,
            tag = 'label',
            icon,
            variant = 'default',
            inputClass,
            showLabel = true,
            prefix = '',
            suffix = '',
            ...props
        },
        ref,
    ) => {
        const Component = tag as any;

        return (
            <Component
                htmlFor={id}
                className={classNames(
                    'input-block',
                    `input-block-${variant}`,
                    {
                        'input-block--icon': icon,
                    },
                    props.className,
                )}
            >
                <input
                    {...props}
                    ref={ref as React.LegacyRef<HTMLInputElement>}
                    id={id}
                    value={`${prefix}${props.value}${suffix}`}
                    onChange={(event) => {
                        props.onChange?.(event);
                    }}
                    onBlur={(event) => {
                        props.onBlur?.(event);
                    }}
                    className={classNames('input-block__input', inputClass, {
                        'is-error': !valid,
                        'not-empty': !!props.value,
                    })}
                />
                {label && showLabel && <span className="input-block__label">{label}</span>}
                {icon ? <span className="input-block__icon">{icon}</span> : null}
                <AnimatePresence>
                    {message && (
                        <m.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
                            exit={{
                                opacity: 0,
                            }}
                            className="input-block__message"
                        >
                            {message}
                        </m.span>
                    )}
                </AnimatePresence>
            </Component>
        );
    },
);

Input.displayName = 'Input';

export default Input;
