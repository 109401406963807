export const removeNullishAndUndefinedFromObject = <T = unknown>(obj: Record<string, T>) => {
    const newObj = { ...obj };

    for (const key in newObj) {
        if (
            typeof newObj[key] === 'undefined' ||
            newObj[key] === null ||
            (Array.isArray(newObj[key]) && (newObj[key] as T[]).length === 0)
        ) {
            delete newObj[key];
        }
    }

    return newObj;
};
