import { Category, Product } from '@/types';
import { useEffect, useMemo, useState } from 'react';
import Button from '@/components/shared/Button';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useIsCategoriesFiltersPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { FILTERS_CATEGORIES_POPUP_NAME } from '../CategoriesFiltersPopup/CategoriesFiltersPopup';
import { useViewedContext } from '../ViewedProvider/ViewedProvider';
import { useCompareContext } from '../CompareProvider/CompareProvider';

interface Props {
    products: Product[];
    item?: Category;
    viewed?: boolean;
}

const CategoryButton = ({ products, item, viewed = false }: Props) => {
    const router = useRouter();
    const { closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsCategoriesFiltersPopupOpenedValue();
    const categoryId = item?.id ?? 0;
    const categoryText = item?.name ?? 'ВСЕ';
    const viewedContext = useViewedContext();
    const compareContext = useCompareContext();
    const productCount = useMemo(
        () =>
            categoryId === 0
                ? products.length
                : products.filter((innerItem) => innerItem.category_id === categoryId).length,
        [products, categoryId],
    );

    const { activeCategoryId, setActiveCategoryId } = viewed ? viewedContext : compareContext;

    useEffect(() => {
        if (products.filter((item) => item.category_id === activeCategoryId).length === 0 && products.length > 0) {
            viewed ? setActiveCategoryId(0) : setActiveCategoryId(products[0].category_id);
        }
    }, [activeCategoryId, products, setActiveCategoryId, viewed]);

    return (
        <Button
            geometryVariant="mustache"
            disabled={productCount < 1}
            onClick={() => {
                if (categoryId !== activeCategoryId) {
                    setActiveCategoryId(categoryId);
                }
                viewed &&
                    router.push({ pathname: router.pathname, query: { ...router.query, page: 1 } }, undefined, {
                        shallow: true,
                    });
                isOpened && closePopup(FILTERS_CATEGORIES_POPUP_NAME);
            }}
            className={classNames('category-button', {
                'category-button--active': categoryId ? categoryId === activeCategoryId : activeCategoryId === 0,
            })}
        >
            <div className="btn-mustache-wrapper">
                <div className="text-xs mustache-category-text">{categoryText}</div>
                <span className="m-text-xs favorites-count">{`[${productCount}]`}</span>
            </div>
        </Button>
    );
};

export default CategoryButton;
