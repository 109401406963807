import Link from '@/components/shared/Link';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import Popup from '@/components/shared/Popup';
import {
    useIsAppNavOpenedValue,
    useIsCatalogLandingPopupOpenedValue,
    useSetOpenedPopupsState,
} from '@/atoms/opened-popups';
import { Fragment, useRef, useMemo } from 'react';
import { m, useIsomorphicLayoutEffect } from 'framer-motion';
import { CATALOG_LANDING_POPUP_NAME } from '@/components/general/CatalogLandingPopup/CatalogLandingPopup';
import classNames from 'classnames';
import { FAVORITES_COOKIES_NAME, FAVORITES_POPUP_NAME } from '@/components/shared/FavoritesPopup/FavoritesPopup';
import { useCookies } from 'react-cookie';
import { useDebounce } from '@/hooks/use-debounce';
import Button from '@/components/shared/Button';

import { FEEDBACK_POPUP_NAME } from '@/components/general/FeedbackPopup/FeedbackPopup';
import { useSetSelectedServiceValueFormState } from '@/atoms/form/selected-service-value-form-state';
import FavoritesSVG from '@/svg/favorites-empty.svg';
import CompareSVG from '@/svg/compare.svg';
import ViewedSVG from '@/svg/viewed.svg';
import PhoneSVG from '@/svg/phone.svg';
import debounce from 'lodash.debounce';
import { PHONE_BUTTON_POPUP_NAME } from '@/components/general/PhoneButton/PhoneButton';
import { COMPARE_COOKIES_NAME } from '@/components/shared/Card/CompareButton';
import { CATEGORIES_SLUGS } from '@/api/categories';
import { useIsClient } from '@/hooks/use-is-client';
import { YD_NEW_ID } from '@/metrics/ym';

const links = [
    {
        text: 'Каталог',
        popup: CATALOG_LANDING_POPUP_NAME,
        type: 'catalog',
        showOnDesktop: false,
    },
    {
        text: 'Главная',
        url: '/',
        type: 'link',
        showOnDesktop: true,
    },
    {
        text: 'О компании',
        url: '/about',
        type: 'link',
        showOnDesktop: true,
    },
    {
        text: 'Партнёрство и услуги',
        url: '/services',
        type: 'link',
        showOnDesktop: true,
    },
    {
        text: 'Статьи',
        url: '/blog',
        type: 'link',
        showOnDesktop: true,
    },

    {
        text: 'Контакты',
        url: '/contacts',
        type: 'link',
        showOnDesktop: true,
    },
    {
        text: 'Избранное',
        popup: FAVORITES_POPUP_NAME,
        type: 'popup',
        showOnDesktop: false,
        lineBefore: true,
    },
    {
        text: 'Сравнить товар',
        type: 'link',
        url: '/compare',
        showOnDesktop: false,
    },
    {
        text: 'Просмотренные товары',
        url: '/viewed',
        type: 'link',
        showOnDesktop: false,
    },
];

const variants = {
    hidden: {
        opacity: 0,
        y: -10,
        transition: { duration: 0.4 },
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.5 },
    },
};

export const APP_NAV_POPUP_NAME = 'app-nav-popup';
const MotionLink = m(Link);

type ThemeName = 'black' | 'gray-brown';

const compareKeys = Object.keys(CATEGORIES_SLUGS).map((item) => `${COMPARE_COOKIES_NAME}-${item}`);

const Nav = () => {
    const { openPopup, closePopup, setOpenedPopups } = useSetOpenedPopupsState();
    const [device] = useMediaQueryDeviceState();
    const closeAppNavPopup = () => closePopup(APP_NAV_POPUP_NAME);
    const popupInner = useRef<HTMLDivElement>(null);
    const isAppNavPopupOpened = useIsAppNavOpenedValue();
    const isCatalogPopupOpened = useIsCatalogLandingPopupOpenedValue();
    const debouncedIsCatalogPopupOpened = useDebounce(isCatalogPopupOpened, 50);
    const closeCatalogPopup = () => closePopup(CATALOG_LANDING_POPUP_NAME);
    const [cookies] = useCookies([
        FAVORITES_COOKIES_NAME,
        ...Object.keys(CATEGORIES_SLUGS).map((item) => `${COMPARE_COOKIES_NAME}-${item}`),
    ]);
    const isClient = useIsClient();
    const burgerEl = useRef<HTMLButtonElement>(null);

    const setServiceValueFormState = useSetSelectedServiceValueFormState();

    const compareLength = useMemo(() => {
        return compareKeys.reduce((sum, key) => {
            if (cookies[key]) {
                return sum + cookies[key].length;
            }
            return sum;
        }, 0);
    }, [cookies]);

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (burgerEl.current && popupInner.current) {
                const rect = burgerEl.current.getBoundingClientRect();
                popupInner.current.style.top = `${Math.round(rect.bottom)}px`;
                popupInner.current.style.left = `${Math.round(rect.left)}px`;
            }
        };

        const debouncedOnResize = debounce(onResize, 100);
        window.addEventListener('resize', debouncedOnResize);
        onResize();
        document.addEventListener('new-page-ready', onResize);

        return () => {
            window.removeEventListener('resize', debouncedOnResize);
            document.removeEventListener('new-page-ready', onResize);
        };
    }, []);

    return (
        <nav
            className={classNames('app-nav', {
                'app-nav--catalog-opened': isCatalogPopupOpened,
                'app-nav--burger--opened': isAppNavPopupOpened,
            })}
        >
            <button
                type="button"
                className="app-nav-link app-nav-link--catalog"
                onClick={() => {
                    isCatalogPopupOpened ? closeCatalogPopup() : openPopup(CATALOG_LANDING_POPUP_NAME);
                }}
            >
                <div
                    className={classNames('app-nav-link--catalog-text-wrapper text-xs', {
                        'is-opened': debouncedIsCatalogPopupOpened,
                    })}
                >
                    <div className="app-nav-link--catalog-text-wrapper__text">Каталог</div>
                </div>
                <div className="app-nav-catalog__icon" />
            </button>

            <button
                className={classNames('app-nav-burger', { 'is-opened': isAppNavPopupOpened })}
                onClick={() => {
                    if (isAppNavPopupOpened) {
                        closeAppNavPopup();
                    } else {
                        setOpenedPopups([APP_NAV_POPUP_NAME]);
                    }
                }}
                ref={burgerEl}
                aria-label="Меню"
            >
                <div className="app-nav-burger__text text-xs">Меню</div>
                <div className="app-nav-burger__icon">
                    <span />
                    <span />
                </div>
            </button>
            <div className="app-nav-links">
                <button
                    onClick={() => {
                        openPopup(FAVORITES_POPUP_NAME);
                    }}
                    className="app-nav-link__item"
                    aria-label="Открыть избранное"
                >
                    <div className="app-nav-link__item-icon">
                        <FavoritesSVG />
                    </div>
                    <div className="app-nav-link__item-count">
                        {isClient && cookies.favorites && cookies.favorites.length > 0 && (
                            <span className="m-text-xs favorites-count">{`[ ${cookies.favorites.length} ]`}</span>
                        )}
                    </div>
                </button>
                <Link href={'/compare'} className="app-nav-link__item" aria-label="перейти к сравнению">
                    <div className="app-nav-link__item-icon">
                        <CompareSVG />
                    </div>
                    <div className="app-nav-link__item-count">
                        {isClient && compareLength > 0 && (
                            <span className="m-text-xs favorites-count">{`[ ${compareLength} ]`}</span>
                        )}
                    </div>
                </Link>
                <Link href={'/viewed'} className="app-nav-link__item" aria-label="перейти к просматриваемым">
                    <div className="app-nav-link__item">
                        <div className="app-nav-link__item-icon">
                            <ViewedSVG />
                        </div>
                    </div>
                </Link>
            </div>

            <Popup className="app-nav-popup" name={APP_NAV_POPUP_NAME} overlay={true}>
                <m.div
                    variants={{
                        visible: {
                            opacity: 1,
                            transition: {
                                staggerChildren: 0.07,
                                duration: 0.5,
                                delay: 0.3,
                            },
                        },
                        hidden: {
                            opacity: 0,
                        },
                    }}
                    initial="hidden"
                    animate={isAppNavPopupOpened ? 'visible' : 'hidden'}
                    className="app-nav-popup-content"
                    ref={popupInner}
                >
                    <div className="burger-buttons">
                        <Button
                            icon={<PhoneSVG />}
                            variant="primary"
                            iconReverse
                            onClick={() => {
                                setOpenedPopups([PHONE_BUTTON_POPUP_NAME]);
                            }}
                        >
                            Связаться
                        </Button>
                        <Button
                            variant="primary"
                            className="app-nav-burger-callback-button"
                            onClick={() => {
                                setServiceValueFormState('под заказ');
                                setOpenedPopups([FEEDBACK_POPUP_NAME]);
                            }}
                        >
                            под заказ
                        </Button>
                    </div>
                    {device === 'desktop'
                        ? links.map((link, i) => {
                              switch (link.type) {
                                  case 'popup':
                                      return link.popup && link.showOnDesktop ? (
                                          <m.button
                                              variants={variants}
                                              type="button"
                                              className="app-nav-popup-link"
                                              key={i}
                                              onClick={() => {
                                                  closeAppNavPopup();
                                                  openPopup(link.popup);
                                              }}
                                              aria-label={link.text}
                                          >
                                              <span className="text-xs">{link.text}</span>
                                              {isClient && link.popup === FAVORITES_POPUP_NAME && cookies.favorites && (
                                                  <span className="m-text-xs favorites-count">
                                                      {'[' + cookies.favorites.length + ']'}
                                                  </span>
                                              )}
                                          </m.button>
                                      ) : null;
                                  case 'link':
                                      return link.url && link.showOnDesktop ? (
                                          <MotionLink
                                              variants={variants}
                                              className="app-nav-popup-link"
                                              key={i}
                                              href={link.url}
                                              onClick={() => {
                                                  closeAppNavPopup();
                                              }}
                                          >
                                              <div className="text-xs">{link.text}</div>
                                          </MotionLink>
                                      ) : null;
                                  default:
                                      return null;
                              }
                          })
                        : links.map((link, i) => {
                              switch (link.type) {
                                  case 'catalog':
                                      return (
                                          <m.button
                                              type="button"
                                              className="app-nav-popup-link"
                                              onClick={() => {
                                                  setOpenedPopups([CATALOG_LANDING_POPUP_NAME]);
                                              }}
                                              key={i}
                                          >
                                              <div className={classNames('h1', {})}>Каталог</div>
                                          </m.button>
                                      );
                                  case 'popup':
                                      return link.popup ? (
                                          <Fragment key={i}>
                                              {link.lineBefore && <div className="menu-line" />}
                                              <m.button
                                                  variants={variants}
                                                  type="button"
                                                  className="app-nav-popup-link"
                                                  onClick={() => {
                                                      closeAppNavPopup();
                                                      openPopup(link.popup);
                                                  }}
                                              >
                                                  <span className="text-xs">{link.text}</span>
                                                  {isClient &&
                                                      link.popup === FAVORITES_POPUP_NAME &&
                                                      cookies.favorites &&
                                                      cookies.favorites.length > 0 && (
                                                          <span className="m-text-xs favorites-count">
                                                              {'[' + cookies.favorites.length + ']'}
                                                          </span>
                                                      )}
                                              </m.button>
                                          </Fragment>
                                      ) : null;
                                  case 'link':
                                      return link.url ? (
                                          <MotionLink
                                              variants={variants}
                                              className="app-nav-popup-link"
                                              key={i}
                                              href={link.url}
                                              onClick={() => {
                                                  closeAppNavPopup();
                                              }}
                                          >
                                              <div className="text-xs">{link.text}</div>
                                              {isClient && link.url === '/compare' && compareLength > 0 && (
                                                  <span className="m-text-xs favorites-count">
                                                      {'[' + compareLength + ']'}
                                                  </span>
                                              )}
                                          </MotionLink>
                                      ) : null;
                                  default:
                                      return null;
                              }
                          })}
                </m.div>
            </Popup>
            <Button
                variant="bordered-light-bg-dark"
                className="app-nav-callback-button app-nav-callback-button--desktop"
                onClick={() => {
                    setServiceValueFormState('под заказ');
                    openPopup(FEEDBACK_POPUP_NAME);
                    window.ym?.(YD_NEW_ID, 'reachGoal', 'click_on_request');
                }}
            >
                под заказ
            </Button>
        </nav>
    );
};

export default Nav;
