import { CATALOG_LANDING_POPUP_NAME } from '@/components/general/CatalogLandingPopup/CatalogLandingPopup';
import { FEEDBACK_POPUP_NAME } from '@/components/general/FeedbackPopup/FeedbackPopup';
import { PHONE_BUTTON_POPUP_NAME } from '@/components/general/PhoneButton/PhoneButton';
import { FILTERS_POPUP_NAME } from '@/components/layout/CatalogFilters/FiltersPopup';
import { FILTERS_CATEGORIES_POPUP_NAME } from '@/components/general/CategoriesFiltersPopup/CategoriesFiltersPopup';
import { VIDEO_POPUP_NAME } from '@/components/general/VideoPopup/VideoPopup';
import { SORT_POPUP_NAME } from '@/components/layout/CatalogFilters/SortPopup';
import { IMAGE_POPUP_NAME } from '@/components/general/ImagePopup/ImagePopup';
import { APP_NAV_POPUP_NAME } from '@/components/layout/Nav/Nav';
import { SEARCH_POPUP_NAME } from '@/components/layout/SearchPopup/SearchPopup';
import { BLOG_FILTERS_POPUP_NAME } from '@/components/shared/BlogFilters/BlogFiltersPopup';
import { FAVORITES_POPUP_NAME } from '@/components/shared/FavoritesPopup/FavoritesPopup';
import { addUniqueItemToArray } from '@/utils/arrays/add-unique-item-to-array';
import { useCallback } from 'react';
import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';

const openedPopupsState = atom<string[]>({
    key: 'openedPopupsState',
    default: [],
});

export const useOpenedPopupsValue = () => useRecoilValue(openedPopupsState);

export const useSetOpenedPopupsState = () => {
    const setOpenedPopups = useSetRecoilState(openedPopupsState);

    const openPopup = useCallback(
        (name: string) => setOpenedPopups((prevOpenedPopups) => addUniqueItemToArray(prevOpenedPopups, name)),
        [setOpenedPopups],
    );

    const closePopup = useCallback(
        (name: string) =>
            setOpenedPopups((prevOpenedPopups) => prevOpenedPopups.filter((popupName) => popupName !== name)),
        [setOpenedPopups],
    );

    return { setOpenedPopups, openPopup, closePopup };
};

const isAppNavOpenedState = selector({
    key: 'isAppNavOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(APP_NAV_POPUP_NAME),
});

export const useIsAppNavOpenedValue = () => useRecoilValue(isAppNavOpenedState);

const isCatalogLandingPopupOpenedState = selector({
    key: 'isCatalogLandingOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(CATALOG_LANDING_POPUP_NAME),
});

export const useIsCatalogLandingPopupOpenedValue = () => useRecoilValue(isCatalogLandingPopupOpenedState);

const isFeedbackPopupOpenedState = selector({
    key: 'isFeedbackPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(FEEDBACK_POPUP_NAME),
});

export const useIsFeedbackPopupOpenedValue = () => useRecoilValue(isFeedbackPopupOpenedState);

const isPhoneButtonPopupOpenedState = selector({
    key: 'isPhoneButtonPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(PHONE_BUTTON_POPUP_NAME),
});

export const useIsPhoneButtonPopupOpenedValue = () => useRecoilValue(isPhoneButtonPopupOpenedState);

const isFiltersPopupOpenedState = selector({
    key: 'isFiltersPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(FILTERS_POPUP_NAME),
});

export const useIsFiltersPopupOpenedValue = () => useRecoilValue(isFiltersPopupOpenedState);

const isCategoriesFiltersPopupOpenedState = selector({
    key: 'isCategoriesFiltersPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(FILTERS_CATEGORIES_POPUP_NAME),
});

export const useIsCategoriesFiltersPopupOpenedValue = () => useRecoilValue(isCategoriesFiltersPopupOpenedState);

const isVideoPopupOpenedState = selector({
    key: 'isVideoPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(VIDEO_POPUP_NAME),
});

export const useIsVideoPopupOpenedValue = () => useRecoilValue(isVideoPopupOpenedState);

const isSortPopupOpenedState = selector({
    key: 'isSortPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(SORT_POPUP_NAME),
});

export const useIsSortPopupOpenedValue = () => useRecoilValue(isSortPopupOpenedState);

const isImagePopupOpenedState = selector({
    key: 'isImagePopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(IMAGE_POPUP_NAME),
});

export const useIsImagePopupOpenedValue = () => useRecoilValue(isImagePopupOpenedState);

const isSearchPopupOpenedState = selector({
    key: 'isSearchPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(SEARCH_POPUP_NAME),
});

export const useIsSearchPopupOpenedValue = () => useRecoilValue(isSearchPopupOpenedState);

const isFavoritesPopupOpenedState = selector({
    key: 'isFavoritesPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(FAVORITES_POPUP_NAME),
});

export const useIsFavoritesPopupOpenedValue = () => useRecoilValue(isFavoritesPopupOpenedState);

const isBlogFiltersPopupOpenedState = selector({
    key: 'isBlogFiltersPopupOpenedState',
    get: ({ get }) => get(openedPopupsState).includes(BLOG_FILTERS_POPUP_NAME),
});

export const useIsBlogFiltersPopupOpenedValue = () => useRecoilValue(isBlogFiltersPopupOpenedState);
