import { ImageShape } from '@/types';
import { useIsCatalogLandingPopupOpenedValue } from '@/atoms/opened-popups';
import classNames from 'classnames';
import { m, AnimatePresence } from 'framer-motion';
import { CategoryId } from '@/api/categories';
import dynamic from 'next/dynamic';

const Popup = dynamic(() => import('@/components/shared/Popup'));
const SecondStep = dynamic(() => import('./SecondStep'));

interface Props {
    categories: {
        id: CategoryId;
        name: string;
        slug: string;
        image: ImageShape | null;
        lottie: string | null;
        amount: number | null;
    }[];
}

export const CATALOG_LANDING_POPUP_NAME = 'catalog-landing';

const CatalogLandingPopup = ({ categories }: Props) => {
    const isOpened = useIsCatalogLandingPopupOpenedValue();

    return (
        <Popup
            name={CATALOG_LANDING_POPUP_NAME}
            className={classNames('catalog-landing-popup', { 'catalog-landing-popup--opened': isOpened })}
        >
            <m.div className="catalog-landing-description m-text-xs" key="landing-description">
                <AnimatePresence mode="wait">
                    {isOpened && (
                        <m.div
                            className="clip-container"
                            initial={{ y: '100%' }}
                            animate={{
                                y: 0,
                                transition: {
                                    delay: 0.4,
                                    duration: 0.4,
                                },
                            }}
                            exit={{
                                y: '-100%',
                                transition: {
                                    duration: 0.6,
                                },
                            }}
                        >
                            <m.div
                                initial={{ y: '-100%' }}
                                animate={{
                                    y: 0,
                                    transition: {
                                        delay: 0.4,
                                        duration: 0.4,
                                    },
                                }}
                                exit={{
                                    y: '100%',
                                    transition: {
                                        duration: 0.6,
                                    },
                                }}
                            >
                                Выберите тип товара
                            </m.div>
                        </m.div>
                    )}
                </AnimatePresence>
            </m.div>
            <SecondStep categories={categories} />
        </Popup>
    );
};

export default CatalogLandingPopup;
