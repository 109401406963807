import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const productType = atom<string[]>({
    key: 'productTypeState',
    default: [],
});

export const useProductTypeValue = () => useRecoilValue(productType);

export const useProductTypeState = () => useSetRecoilState(productType);
