import { CDN_DOMAIN, fromCDN } from '@/cdn';
import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { YM_ID } from '@/metrics/ym';

interface Props {
    meta: Partial<{
        baseTitle: string;
        title: string;
        description: string;
        ogImage: string;
    }>;
}

const AppHead = ({ meta }: Props) => {
    const pathname = usePathname();
    const title = meta.title ? `${meta.title} — ${meta.baseTitle}` : meta.baseTitle;

    return (
        <Head>
            <script
                dangerouslySetInnerHTML={{
                    __html: `
                        (function(m,e,t,r,i,k,a){m[i]=m[i] || function(){(m[i].a=m[i].a || []).push(arguments)};
                            m[i].l=1*new Date();
                            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                            ym(${YM_ID}, "init", {
                                    clickmap:true,
                                    trackLinks:true,
                                    accurateTrackBounce:true,
                                    webvisor:true

                            });
                        `,
                }}
            />
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={meta.description} />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            {process.env.HOST && (
                <meta property="og:url" content={process.env.HOST + (pathname === '/' ? '' : pathname)} />
            )}
            <meta property="og:locale" content="ru" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={meta.description} />
            <meta property="og:image" content={meta.ogImage} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:image" content={meta.ogImage} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={meta.description} />
            <meta name="yandex-verification" content="7902d6757b79ab6d" />
            <link rel="apple-touch-icon" sizes="180x180" href={fromCDN('/img/favicon/apple-touch-icon.png')} />
            <link rel="icon" href={fromCDN('/img/favicon/favicon.png')} />
            {process.env.NODE_ENV === 'production' && (
                <>{process.env.NEXT_PUBLIC_ENV === 'production' && <link rel="preconnect" href={CDN_DOMAIN} />}</>
            )}
            <link
                rel="preload"
                href={fromCDN('/fonts/PPNeueMontrealMono-Medium.woff2')}
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href={fromCDN('/fonts/TT_Neoris_Regular.woff2')}
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href={fromCDN('/fonts/TT_Neoris_Medium.woff2')}
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
        </Head>
    );
};

export default AppHead;
