import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AnimatePresence, animate } from 'framer-motion';
import { useIsFirstRender } from 'usehooks-ts';
import { useAppReadyState } from '@/atoms/app-ready';
import { easeOutQuart } from '@/utils/easings';
import PreloaderCanvas from './PreloaderCanvas';

const Preloader = (props: React.HTMLAttributes<HTMLElement>) => {
    const DURATION = 1;
    const [appReady, setAppReady] = useAppReadyState();
    const [preloaderCompleted, setPreloaderCompleted] = useState(false);
    const [progress, setProgress] = useState(0);
    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        const animation = animate(0, 100, {
            delay: 0.2,
            duration: DURATION,
            ease: easeOutQuart,
            onUpdate: (val) => {
                setProgress(val / 100);
            },
            onComplete: () => {
                setAppReady(true);
            },
        });

        return () => {
            animation.cancel();
        };
    }, [setAppReady]);

    return (
        <AnimatePresence>
            {!preloaderCompleted && (
                <div {...props} className={classNames('preloader', props.className, { 'is-complete': progress === 1 })}>
                    <PreloaderCanvas
                        progress={progress}
                        setPreloaderCompleted={setPreloaderCompleted}
                        className={classNames({ 'is-hidden': isFirstRender })}
                    />
                    <div className="m-text-xs preloader__bottom">
                        <div className="clip-container preloader__counter-wrapper">
                            <div className="preloader__counter">
                                <span>{Math.round(progress * 100)}</span> %
                            </div>
                        </div>
                        <div className="clip-container">
                            <div className="preloader__text">Загрузка</div>
                        </div>
                    </div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default Preloader;
