import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const productDialColors = atom<string[]>({
    key: 'productDialColorsState',
    default: [],
});

export const useProductDialColorsValue = () => useRecoilValue(productDialColors);

export const useProductDialColorsState = () => useSetRecoilState(productDialColors);
