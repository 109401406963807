import Popup from '@/components/shared/Popup';
import { m } from 'framer-motion';
import { useState } from 'react';
import { useIsImagePopupOpenedValue } from '@/atoms/opened-popups';
import classNames from 'classnames';
import Image from 'next/image';
import { ImageShape } from '@/types';

interface Props {
    item: ImageShape;
}

export const IMAGE_POPUP_NAME = 'image';

const ImagePopup = ({ item }: Props) => {
    const isOpened = useIsImagePopupOpenedValue();
    const [popupAnimating, setPopupAnimating] = useState(true);

    return (
        <Popup
            name={IMAGE_POPUP_NAME}
            className={classNames('image-popup', {
                'is-animating': popupAnimating,
            })}
            overlay
        >
            <m.div
                className="image-popup__inner"
                variants={{
                    visible: {
                        transition: {
                            duration: 0.8,
                        },
                        opacity: 1,
                        scale: 1,
                    },
                    hidden: {
                        opacity: 0,
                        scale: 0.8,
                    },
                }}
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
                onAnimationStart={() => {
                    setPopupAnimating(true);
                }}
                onAnimationComplete={() => {
                    setPopupAnimating(false);
                }}
            >
                <div className="image-popup__main">
                    <Image
                        src={item.src}
                        width={item.width}
                        height={item.height}
                        alt=""
                        className="product-slide-main-image"
                    />
                </div>
            </m.div>
        </Popup>
    );
};

export default ImagePopup;
