import { useIsFavoritesPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { m } from 'framer-motion';
import FavoritesEmptySVG from '@/svg/favorites-empty.svg';
import FavoriteItem from '../FavoriteItem';
import { useFavoritesPickedState } from '@/atoms/favorites-picked-state';
import { useCookies } from 'react-cookie';
import { useProductsQuery } from '@/api/products';
import { useSetSelectedServiceValueFormState } from '@/atoms/form/selected-service-value-form-state';
import { useSetSelectedItemsState } from '@/atoms/form/selected-items-state';
import { useFormStepState } from '@/atoms/form/step-state';
import { FEEDBACK_POPUP_NAME } from '@/components/general/FeedbackPopup/FeedbackPopup';
import { Product } from '@/types';
import { useEffect } from 'react';
import Button from '../Button';
import Popup from '@/components/shared/Popup';

function getNameById(ids: number[], objects: Product[]): string[] {
    const names: string[] = [];

    ids.forEach((id) => {
        const foundObject = objects.find((obj) => obj.id === id);

        if (foundObject) {
            names.push(foundObject.name);
        }
    });

    return names;
}

export const FAVORITES_POPUP_NAME = 'favorites';
export const FAVORITES_COOKIES_NAME = 'favorites';

const FavoritesPopup = () => {
    const { openPopup, closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsFavoritesPopupOpenedValue();
    const close = () => closePopup(FAVORITES_POPUP_NAME);
    const setServiceValueFormState = useSetSelectedServiceValueFormState();
    const setSelectedItems = useSetSelectedItemsState();
    const [, setStep] = useFormStepState();

    const [favoritesPickedState, setFavoritesPickedState] = useFavoritesPickedState();
    const [cookies, setCookies] = useCookies([FAVORITES_COOKIES_NAME]);

    const { data } = useProductsQuery({
        queryParams: {
            favorites: cookies[FAVORITES_COOKIES_NAME]?.length > 0 ? cookies[FAVORITES_COOKIES_NAME] : undefined,
            limit: 100,
        },
        enabled: cookies[FAVORITES_COOKIES_NAME]?.length > 0,
    });
    const items = data?.data?.items ?? [];
    const count = cookies[FAVORITES_COOKIES_NAME]?.length > 0 ? items.length : 0;

    useEffect(() => {
        if (!isOpened) {
            setFavoritesPickedState([]);
        }
    }, [isOpened, setFavoritesPickedState]);

    useEffect(() => {}, []);

    return (
        <Popup name={FAVORITES_POPUP_NAME} overlay className="favorites-popup">
            <m.div
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.07,
                            duration: 0.5,
                        },
                        opacity: 1,
                        x: 0,
                    },
                    hidden: {
                        opacity: 0,
                        x: '100%',
                    },
                }}
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
                className="favorites-popup__inner"
            >
                <div className="favorite-header">
                    <Button
                        geometryVariant="square-bracket"
                        onClick={close}
                        aria-label="Закрыть избранное"
                        className="favorite-popup-close close-button"
                    >
                        X
                    </Button>
                    <div className="favorite-title">
                        <div className="h2">Избранное</div>{' '}
                        <div className="favorite-count m-text-xs">
                            {'['}
                            {count}
                            {']'}
                        </div>
                    </div>
                </div>
                <div className="favorite-body">
                    {cookies[FAVORITES_COOKIES_NAME]?.length > 0 && items.length > 1 && (
                        <label htmlFor={`favorite-all`} className="checkbox favorite-all-checkbox">
                            <input
                                className="visually-hidden"
                                type="checkbox"
                                id={`favorite-all`}
                                onChange={(event) =>
                                    setFavoritesPickedState(event.target.checked ? items.map((item) => item.id) : [])
                                }
                                checked={favoritesPickedState.length === items.length}
                            />
                            <div className="checkbox-element" />
                            <div className="checkbox-text text-s">Выбрать все</div>
                        </label>
                    )}
                    {cookies[FAVORITES_COOKIES_NAME]?.length > 0 && items.length > 0 ? (
                        <ul className="list-unstyled">
                            {items.map((item, i) => {
                                if (cookies[FAVORITES_COOKIES_NAME].includes(item.id)) {
                                    return (
                                        <li key={i}>
                                            <FavoriteItem
                                                image={item.images[0]}
                                                productId={item.id}
                                                cardName={item.name}
                                                price={item.price}
                                                favorite={item.favorite}
                                                picked={favoritesPickedState.includes(item.id)}
                                                showCheckbox={items.length > 1}
                                                id={item.id}
                                            />
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    ) : (
                        <div className="favorite-empty-message">
                            Нажмите на{' '}
                            <span className="favorite-empty-message__icon">
                                <FavoritesEmptySVG />
                            </span>
                            , чтобы добавить продукт в избранное.
                        </div>
                    )}
                </div>
                <div className="favorite-footer product-cta">
                    <Button
                        variant="bordered-light"
                        className="product-trade-in-button"
                        disabled={count === 0}
                        onClick={() => {
                            setCookies(
                                FAVORITES_COOKIES_NAME,
                                cookies[FAVORITES_COOKIES_NAME].filter(
                                    (item: number) => !favoritesPickedState.includes(item),
                                ),
                            );
                            if (cookies[FAVORITES_COOKIES_NAME].length === 1) {
                                setCookies(FAVORITES_COOKIES_NAME, []);
                            }
                            if (cookies[FAVORITES_COOKIES_NAME].length === 0) {
                                close();
                            }
                        }}
                    >
                        Удалить
                    </Button>
                    <Button
                        variant="primary"
                        disabled={count === 0}
                        onClick={() => {
                            close();
                            setServiceValueFormState('buy');
                            setSelectedItems(getNameById(favoritesPickedState, items));
                            if (favoritesPickedState.length > 1) {
                                setStep('form');
                            }
                            openPopup(FEEDBACK_POPUP_NAME);
                        }}
                    >
                        Купить
                    </Button>
                </div>
            </m.div>
        </Popup>
    );
};

export default FavoritesPopup;
