/**
 * Все провайдеры подключаются в этом файле.
 */

import { ReactNode, useState } from 'react';
import { RecoilRoot, RecoilEnv } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LazyMotion, m } from 'framer-motion';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

const loadFeatures = () => import('@/lazy-motion').then((res) => res.default);

const Providers = ({ children }: { children: ReactNode }) => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnMount: false,
                        refetchOnWindowFocus: false,
                    },
                },
            }),
    );

    return (
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <LazyMotion features={loadFeatures} strict>
                    {children}
                </LazyMotion>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </RecoilRoot>
    );
};

export default Providers;
