import { CATEGORIES_IDS, CategoryId } from '@/api/categories';
import { Product } from '@/types';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

const CompareContext = createContext<{
    activeCategoryId: CategoryId | 0;
    setActiveCategoryId: Dispatch<SetStateAction<CategoryId | 0>>;
    activeIndex: number;
    setActiveIndex: Dispatch<SetStateAction<number>>;
}>({
    activeCategoryId: CATEGORIES_IDS['watch'],
    setActiveCategoryId: () => {},
    activeIndex: 0,
    setActiveIndex: () => {},
});

export const useCompareContext = () => useContext(CompareContext);

interface Props {
    children?: ReactNode;
    products?: Product[];
}

const CompareProvider = ({ children, products }: Props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeCategoryId, setActiveCategoryId] = useState<CategoryId | 0>(
        products?.map((product) => product.category_id).sort((a, b) => a - b)[0] ?? 1,
    );

    return (
        <CompareContext.Provider
            value={{
                activeCategoryId,
                setActiveCategoryId,
                activeIndex,
                setActiveIndex,
            }}
        >
            {children}
        </CompareContext.Provider>
    );
};

export default CompareProvider;
