import { useEffect } from 'react';
import { lockBodyScroll, unlockBodyScroll } from '@/utils/dom';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import { useScrollLockState } from '@/atoms/scroll-lock';
import { useOpenedPopupsValue } from '@/atoms/opened-popups';
import { useAppReadyState } from '@/atoms/app-ready';
import { useRoubleToDollarCourseState } from '@/atoms/rouble-to-dollar-course';
import { useIsMounted } from 'usehooks-ts';

const AppInits = ({ course }: { course: string }) => {
    const [_, setMediaQueryDeviceState] = useMediaQueryDeviceState();
    const [scrollLocked, setScrollLocked] = useScrollLockState();
    const openedPopups = useOpenedPopupsValue();
    const [appReady] = useAppReadyState();
    const [, setCourse] = useRoubleToDollarCourseState();
    const isMounted = useIsMounted();

    useEffect(() => {
        if (course) {
            setCourse(parseInt(course, 10));
        }
    }, [course, setCourse]);

    /**
     * Блокирование скролла
     */
    useEffect(() => {
        setScrollLocked(!appReady || openedPopups.length > 0);
    }, [appReady, openedPopups.length, setScrollLocked]);

    /**
     * Блокирование скролла страницы
     */
    useEffect(() => {
        if (scrollLocked) {
            lockBodyScroll();
        } else {
            unlockBodyScroll();
        }
    }, [scrollLocked]);

    useEffect(() => {
        const setDevice = () => {
            if (isMounted()) {
                switch (true) {
                    case matchMedia('(max-width: 767px)').matches:
                        setMediaQueryDeviceState('vertical-mobile');
                        break;
                    case matchMedia('(max-width: 900px) and (orientation: landscape)').matches:
                        setMediaQueryDeviceState('horizontal-mobile');
                        break;
                    case matchMedia('(min-width: 768px) and (max-width: 1199px) and (orientation: portrait)').matches:
                        setMediaQueryDeviceState('vertical-tablet');
                        break;
                    case matchMedia('(min-width: 768px) and (max-width: 1199px) and (orientation: landscape)').matches:
                        setMediaQueryDeviceState('horizontal-tablet');
                        break;
                    default:
                        setMediaQueryDeviceState('desktop');
                        break;
                }
            }
        };

        const onResize = () => {
            setDevice();
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', setDevice);
    }, [setMediaQueryDeviceState, isMounted]);

    return null;
};

export default AppInits;
