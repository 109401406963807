import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const productGender = atom<number[]>({
    key: 'productGenderState',
    default: [],
});

export const useProductGenderValue = () => useRecoilValue(productGender);

export const useProductGenderState = () => useSetRecoilState(productGender);
