import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const productColor = atom<string[]>({
    key: 'productColorState',
    default: [],
});

export const useProductColorValue = () => useRecoilValue(productColor);

export const useProductColorState = () => useSetRecoilState(productColor);
