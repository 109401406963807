import { useEffect, useState } from 'react';

export function useDpr() {
    const [dpr, setDpr] = useState(1);

    useEffect(() => {
        const onResize = () => {
            setDpr(Math.min(window.devicePixelRatio, 2));
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);

    return dpr;
}
