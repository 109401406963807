import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const defaultPowers = atom<{
    min: number | null;
    max: number | null;
}>({
    key: 'defaultPowersState',
    default: {
        min: null,
        max: null,
    },
});

export const useDefaultPowersValue = () => useRecoilValue(defaultPowers);

export const useDefaultPowersState = () => useSetRecoilState(defaultPowers);
