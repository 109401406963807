import classNames from 'classnames';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { AnimationPlaybackControls, animate, useAnimationFrame } from 'framer-motion';
import useElementSize from '@/hooks/use-element-size';
import { mergeRefs } from '@/utils/merge-refs';
import { easeInOutQuart } from '@/utils/easings';
import { degToRad } from '@/utils/math';
import { COLORS } from '@/colors';
import { useDpr } from '@/hooks/use-dpr';
import { drawCircle } from '@/utils/canvas';

type Props = React.HTMLAttributes<HTMLCanvasElement> & {
    progress: number;
    setPreloaderCompleted: Dispatch<SetStateAction<boolean>>;
};

const PreloaderCanvas = ({ progress, setPreloaderCompleted, ...props }: Props) => {
    const ref = useRef<HTMLCanvasElement | null>(null);
    const leaveProgress = useRef(0);
    const dpr = useDpr();
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [canvasRef, size] = useElementSize<HTMLCanvasElement>();
    const width = size.width * dpr;
    const height = size.height * dpr;
    const dimension = Math.min(width, height) * (2 / dpr);

    const getResponsiveDimension = (value: number) => {
        return (value / 1920) * dimension;
    };

    const centerX = width / 2;
    const centerY = height / 2;
    const largeRadius = (getResponsiveDimension(181) / 2) * dpr;
    const middleRadius = (getResponsiveDimension(90) / 2) * dpr;
    const r1 = (getResponsiveDimension(586) / 2) * dpr;
    const r2 = (getResponsiveDimension(472) / 2) * dpr;
    const LINES_COUNT = 24;

    const drawProgressLine = (render = true, angleInDegrees: number, offset: number) => {
        if (ctx) {
            const startRadius = largeRadius;
            const angle = degToRad(angleInDegrees);
            const startX = centerX + Math.cos(angle) * startRadius;
            const startY = centerY + Math.sin(angle) * startRadius;
            const a1 = width / 2;
            const c1 = a1 / Math.cos(degToRad(30));
            const a2 = height / 2;
            const c2 = a2 / Math.cos(degToRad(60));
            const finalRadius = Math.min(c1, c2);
            const endRadius = (offset + (1 - offset) * progress) * finalRadius;
            const endX = centerX + Math.cos(angle) * endRadius;
            const endY = centerY + Math.sin(angle) * endRadius;
            ctx.moveTo(startX, startY);
            ctx.lineTo(endX, endY);
            if (render) {
                ctx.stroke();
            }
        }
    };

    useEffect(() => {
        let animation: AnimationPlaybackControls | null;

        if (progress === 1) {
            animation = animate(0, 1, {
                onUpdate: (val) => {
                    leaveProgress.current = val;
                },
                onComplete: () => {
                    setPreloaderCompleted(true);
                },
                duration: 1.5,
                ease: easeInOutQuart,
            });
        }

        return () => {
            animation?.complete();
        };
    }, [progress, setPreloaderCompleted]);

    useEffect(() => {
        if (ref.current) {
            setCtx(ref.current.getContext('2d'));
        }
    }, []);

    useAnimationFrame((t) => {
        if (ctx) {
            const time = t * 0.0005;

            ctx.clearRect(0, 0, width, height);
            ctx.save();

            if (progress === 1) {
                // clipping area
                ctx.beginPath();
                ctx.fillStyle = COLORS.BLACK;
                ctx.rect(0, 0, (width / 2) * (1 - leaveProgress.current), height);
                ctx.rect(
                    (width / 2) * (1 + leaveProgress.current),
                    0,
                    (width / 2) * (1 - leaveProgress.current),
                    height,
                );
                ctx.fill();
                ctx.closePath();
                ctx.clip();
            }

            ctx.lineWidth = dpr;
            ctx.strokeStyle = COLORS.WHITE;

            drawCircle(centerX, centerY, r1, ctx);
            drawCircle(centerX, centerY, r2, ctx);

            // large circle
            drawCircle(centerX, centerY, largeRadius, ctx);

            // middle circle
            const middleX = centerX + Math.cos(time) * (largeRadius / 2);
            const middleY = centerY + Math.sin(time) * (largeRadius / 2);
            drawCircle(middleX, middleY, middleRadius, ctx);

            const offset = (r1 - r2) / 3;
            const startRadius = r2 + offset;
            const endRadius = r1 - offset;
            for (let i = 0; i < LINES_COUNT; i++) {
                const angle = degToRad((i / LINES_COUNT) * 360) + time * 0.3;
                const startX = centerX + Math.cos(angle) * startRadius;
                const startY = centerY + Math.sin(angle) * startRadius;
                const endX = centerX + Math.cos(angle) * endRadius;
                const endY = centerY + Math.sin(angle) * endRadius;
                ctx.moveTo(startX, startY);
                ctx.lineTo(endX, endY);
            }

            // progress lines
            drawProgressLine(false, 180 + 30, 0.35);
            drawProgressLine(false, -30, 0.25);
            drawProgressLine(false, 30, 0.35);
            drawProgressLine(false, 180 - 30, 0.6);
            ctx.stroke();

            // middle circle's satellite
            drawCircle(
                middleX + Math.cos(-time - Math.PI / 2) * middleRadius,
                middleY + Math.sin(-time - Math.PI / 2) * middleRadius,
                (getResponsiveDimension(21) / 2) * dpr,
                ctx,
                COLORS.BLACK,
            );

            // large circle's satellite 1
            drawCircle(
                centerX + Math.cos(-time) * largeRadius,
                centerY + Math.sin(-time) * largeRadius,
                (getResponsiveDimension(17) / 2) * dpr,
                ctx,
                COLORS.BLACK,
            );

            // large circle's satellite 2
            drawCircle(
                centerX + Math.cos(-time + (Math.PI / 4) * 3) * largeRadius,
                centerY + Math.sin(-time + (Math.PI / 4) * 3) * largeRadius,
                (getResponsiveDimension(12) / 2) * dpr,
                ctx,
                COLORS.BLACK,
            );

            // large circle's satellite 3
            drawCircle(
                centerX + Math.cos(-time - Math.PI / 2) * largeRadius,
                centerY + Math.sin(-time - Math.PI / 2) * largeRadius,
                (getResponsiveDimension(12) / 2) * dpr,
                ctx,
                COLORS.BLACK,
            );

            ctx.restore();
        }
    });

    return (
        <canvas
            {...props}
            ref={mergeRefs([ref, canvasRef])}
            className={classNames('preloader__canvas', props.className)}
            width={width}
            height={height}
        />
    );
};

export default PreloaderCanvas;
