import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const productCollection = atom<string[]>({
    key: 'productCollectionState',
    default: [],
});

export const useProductCollectionValue = () => useRecoilValue(productCollection);

export const useProductCollectionState = () => useSetRecoilState(productCollection);
