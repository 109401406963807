import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const defaultPrices = atom<{
    min: number | null;
    max: number | null;
}>({
    key: 'defaultPricesState',
    default: {
        min: null,
        max: null,
    },
});

export const useDefaultPricesValue = () => useRecoilValue(defaultPrices);

export const useDefaultPricesState = () => useSetRecoilState(defaultPrices);
