import { CategoryId } from '@/api/categories';
import { atom, useRecoilState } from 'recoil';

export type Filters = Partial<{
    available: 0 | 1;
    'brands[]': number[];
    category: CategoryId;
    'materials[]': number[];
    priceMin: number;
    priceMax: number;
    powerMin: number;
    powerMax: number;
    diameterMin: number;
    diameterMax: number;
    order: 'asc' | 'desc';
    page: number;
    popularity: 0 | 1;
    search: string;
    state: 0 | 1;
    favorite: 0 | 1;
    sort: 'published_at' | 'price';
    'type[]': string[];
    'insert[]': string[];
    'color[]': string[];
    'collection[]': string[];
    'dial_color[]': string[];
    'gender[]': number[];
}>;

const filtersState = atom<Filters>({
    key: 'filtersState',
    default: {},
});

export const useFiltersState = () => useRecoilState(filtersState);
