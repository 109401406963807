export const drawCircle = (
    x: number,
    y: number,
    r: number,
    ctx: CanvasRenderingContext2D,
    fill?: string,
    opacity?: number,
) => {
    if (ctx) {
        if (typeof opacity === 'number') {
            ctx.globalAlpha = opacity;
        }

        ctx.beginPath();
        ctx.arc(x, y, r, 0, 2 * Math.PI);
        ctx.closePath();

        if (fill) {
            ctx.fillStyle = fill;
            ctx.fill();
        }

        ctx.stroke();

        if (typeof opacity === 'number') {
            ctx.globalAlpha = 1;
        }
    }
};

export const drawRotatedEllipse = (
    cx: number,
    cy: number,
    rx: number,
    ry: number,
    angle: number,
    gradientPower: number,
    ctx: CanvasRenderingContext2D,
) => {
    if (ctx) {
        ctx.save(); // Сохраняем текущее состояние контекста
        ctx.translate(cx, cy);
        const gradient = ctx.createLinearGradient(80, 200, 0, 100);
        gradient.addColorStop(0, `rgba(255, 255, 255, ${gradientPower})`);
        gradient.addColorStop(1, 'rgba(255, 255, 255, 1.0)');
        ctx.strokeStyle = gradient;
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.ellipse(0, 0, rx, ry, 0, 0, 2 * Math.PI);

        ctx.stroke();
        ctx.restore(); // Восстанавливаем сохраненное состояние контекста
    }
};

export const rotateEllipse = (h: number, k: number, a: number, b: number, theta: number, t: number) => {
    const x = h + a * Math.cos(t) * Math.cos(theta) - b * Math.sin(t) * Math.sin(theta);
    const y = k + a * Math.cos(t) * Math.sin(theta) + b * Math.sin(t) * Math.cos(theta);
    return { x: x, y: y };
};

export const transformValue = (value: number, lowerThreshold: number, upperThreshold: number): number => {
    if (value < lowerThreshold) {
        return 0;
    } else if (value >= upperThreshold) {
        return 1;
    } else {
        return (value - lowerThreshold) / (upperThreshold - lowerThreshold);
    }
};

export const findIntersection = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    x0: number,
    y0: number,
    r: number,
): { x: number; y: number } | null => {
    const m = (y2 - y1) / (x2 - x1);
    const b = y1 - m * x1;

    const A = 1 + Math.pow(m, 2);
    const B = 2 * (m * b - m * y0 - x0);
    const C = Math.pow(x0, 2) + Math.pow(y0, 2) + Math.pow(b, 2) - Math.pow(r, 2) - 2 * b * y0;

    const discriminant = Math.pow(B, 2) - 4 * A * C;

    if (discriminant < 0) {
        return null;
    }

    const x_intersect1 = (-B + Math.sqrt(discriminant)) / (2 * A);
    const x_intersect2 = (-B - Math.sqrt(discriminant)) / (2 * A);
    const y_intersect1 = m * x_intersect1 + b;
    const y_intersect2 = m * x_intersect2 + b;

    const isOnSegment = (x: number, y: number, x1: number, y1: number, x2: number, y2: number): boolean => {
        return x >= Math.min(x1, x2) && x <= Math.max(x1, x2) && y >= Math.min(y1, y2) && y <= Math.max(y1, y2);
    };

    if (isOnSegment(x_intersect1, y_intersect1, x1, y1, x2, y2)) {
        return { x: x_intersect1, y: y_intersect1 };
    } else if (isOnSegment(x_intersect2, y_intersect2, x1, y1, x2, y2)) {
        return { x: x_intersect2, y: y_intersect2 };
    }

    return null;
};

export const mapToRange = (number: number, maxNumber: number) => {
    if (number < 0 || number > 1) {
        throw new Error('Number must be between 0 and 1');
    }

    return Math.round(number * maxNumber);
};

export const drawImageRotated = (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    x: number,
    y: number,
    width: number,
    height: number,
    angle: number,
) => {
    ctx.save();
    ctx.globalAlpha = 1;
    ctx.translate(x + width / 2, y + height / 2);
    ctx.rotate(angle);
    ctx.drawImage(img, width / -2, height / -2, width, height);
    ctx.restore();
};
