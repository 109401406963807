import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const productInsert = atom<string[]>({
    key: 'productInsertState',
    default: [],
});

export const useProductInsertValue = () => useRecoilValue(productInsert);

export const useProductInsertState = () => useSetRecoilState(productInsert);
