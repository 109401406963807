import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const defaultDiameters = atom<{
    min: number | null;
    max: number | null;
}>({
    key: 'defaultDiametersState',
    default: {
        min: null,
        max: null,
    },
});

export const useDefaultDiametersValue = () => useRecoilValue(defaultDiameters);

export const useDefaultDiametersState = () => useSetRecoilState(defaultDiameters);
