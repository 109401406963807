import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allBrandsWidthState = atom<number>({
    key: 'allBrandsWidthState',
    default: 0,
});

export const useAllBrandsWidthValue = () => useRecoilValue(allBrandsWidthState);

export const useSetAllBrandsWidthState = () => useSetRecoilState(allBrandsWidthState);
