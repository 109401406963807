import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allMaterialsWidthState = atom<number>({
    key: 'allMaterialsWidthState',
    default: 0,
});

export const useAllMaterialsWidthValue = () => useRecoilValue(allMaterialsWidthState);

export const useSetAllMaterialsWidthState = () => useSetRecoilState(allMaterialsWidthState);
