import { ImageShape } from '@/types';
import Link from 'next/link';
import Responsive from '../Responsive';
import Image from 'next/image';
import { toCurrency } from '@/utils/to-currency';
import { useFavoritesPickedState } from '@/atoms/favorites-picked-state';
import { addUniqueItemToArray } from '@/utils/arrays/add-unique-item-to-array';
import dynamic from 'next/dynamic';
import { useTypograf } from '@/hooks/use-typograf';

const FavoriteButton = dynamic(() => import('../Card/FavoriteButton'), { ssr: false });
interface Props {
    image: ImageShape;
    productId: number;
    cardName: string;
    price: number;
    favorite: boolean;
    picked: boolean;
    showCheckbox: boolean;
    id: number;
}

const FavoriteItem = ({ image, productId, cardName, price, favorite, picked, showCheckbox, id }: Props) => {
    const [, setFavoritesPickedState] = useFavoritesPickedState();
    const tpCardName = useTypograf(cardName);
    return (
        <Link href={`/catalog/${productId}`} className="favorite-item">
            {showCheckbox && (
                <label
                    htmlFor={`checkbox-${productId}`}
                    className="checkbox favorite-item-checkbox"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <input
                        className="visually-hidden"
                        type="checkbox"
                        id={`checkbox-${productId}`}
                        checked={picked}
                        onChange={(event) => {
                            event.target.checked
                                ? setFavoritesPickedState((prevPickedState) =>
                                      addUniqueItemToArray(prevPickedState, id),
                                  )
                                : setFavoritesPickedState((prevPickedState) =>
                                      prevPickedState.filter((prevId) => prevId !== id),
                                  );
                        }}
                    />
                    <div className="checkbox-element" />
                </label>
            )}
            <div className="favorite-item__left">
                {image && (
                    <Responsive className="favorite-item__image">
                        <Image
                            src={image.src}
                            alt={cardName}
                            width={image.width}
                            height={image.height}
                            sizes="(max-width: 767px) 27.77vw, (max-width: 1200px) 15.62vw, 6.71vw"
                        />
                    </Responsive>
                )}
            </div>
            <div className="favorite-item__center">
                <div className="favorite-item__name m-text-xs">{tpCardName}</div>
                {price !== 0 ? (
                    <div className="favorite-item__price m-text-xs">{toCurrency(price, 'USD')}</div>
                ) : (
                    <div className="zero-price m-text-xs">под заказ</div>
                )}
            </div>
            <FavoriteButton variant="default" productId={productId} className="favorite-item-favorite-button" />
        </Link>
    );
};

export default FavoriteItem;
