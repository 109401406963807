import Axios from 'axios';
import type { Method } from 'axios';
import { buildKeyGenerator, setupCache } from 'axios-cache-interceptor';

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const baseURL = `${process.env.NEXT_PUBLIC_API_HOST || ''}/api`;
const SLASHES_REGEX = /^\/|\/$/g;

export const axiosAPI = setupCache(Axios.create({ baseURL }), {
    interpretHeader: false,
    ttl: 1000 * 60, // 1 минута
    generateKey: buildKeyGenerator(({ baseURL = '', url = '', method = 'get', params, data }) => {
        baseURL && (baseURL = baseURL.replace(SLASHES_REGEX, ''));
        url && (url = url.replace(SLASHES_REGEX, ''));

        // lowercase method
        method && (method = method.toLowerCase() as Method);

        return {
            url: baseURL + (baseURL && url ? '/' : '') + url,
            params: params as unknown,
            method,
            data,
        };
    }),
});
