import { useCallback, useState } from 'react';
import useIsomorphicLayoutEffect from './use-isomorphic-layout-effect';

interface Size {
    width: number;
    height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, Size] {
    // Mutable values like 'ref.current' aren't valid dependencies
    // because mutating them doesn't re-render the component.
    // Instead, we use a state as a ref to be reactive.
    const [ref, setRef] = useState<T | null>(null);
    const [size, setSize] = useState<Size>({ width: 0, height: 0 });

    // Prevent too many rendering using useCallback
    const handleSize = useCallback(() => {
        if (ref) {
            const rect = ref.getBoundingClientRect();
            setSize({ width: rect.width || 0, height: rect.height || 0 });
        }
    }, [ref]);

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            setTimeout(handleSize, 1);
        };

        const ro = window.ResizeObserver ? new ResizeObserver(onResize) : null;
        onResize();

        if (ro && ref) {
            ro.observe(ref);
        } else {
            window.addEventListener('resize', onResize);
        }

        return () => {
            window.removeEventListener('resize', onResize);
            ro?.disconnect();
        };
    }, [handleSize]);

    return [setRef, size];
}

export default useElementSize;
