import { useIsFeedbackPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { m, AnimatePresence, useIsomorphicLayoutEffect } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useSetSelectedServiceValueFormState } from '@/atoms/form/selected-service-value-form-state';
import { usePopupInnerHeightState } from '@/atoms/form/popup-height';
import classNames from 'classnames';
import { easeOutQuart } from '@/utils/easings';
import { useFormStepState } from '@/atoms/form/step-state';
import { useElementSize } from 'usehooks-ts';
import dynamic from 'next/dynamic';
import Button from '@/components/shared/Button';

const Popup = dynamic(() => import('@/components/shared/Popup'));
const ConnectStep = dynamic(() => import('./ConnectStep'));
const ServiceStep = dynamic(() => import('./ServiceStep'));
const FormStep = dynamic(() => import('./FormStep'));

export const FEEDBACK_POPUP_NAME = 'feedback-popup';

export type StepName = 'connect' | 'service' | 'form';

const FeedbackPopup = () => {
    const { closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsFeedbackPopupOpenedValue();
    const setSelectedServiceValueFormState = useSetSelectedServiceValueFormState();
    const el = useRef<HTMLDivElement>(null);
    const [hint, hintSize] = useElementSize<HTMLDivElement>();
    const closeButton = useRef<HTMLButtonElement>(null);
    const [popupInnerHeight] = usePopupInnerHeightState();
    const [popupOffset, setPopupOffset] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const popupHeight = popupInnerHeight ? popupInnerHeight + popupOffset + hintSize.height : 'auto';
    const [step, setStep] = useFormStepState();

    useEffect(() => {
        if (!isOpened) {
            setSelectedServiceValueFormState(null);
            setStep('connect');
        }
    }, [isOpened, setSelectedServiceValueFormState, setStep]);

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (el.current && closeButton.current) {
                setPopupOffset(
                    parseInt(window.getComputedStyle(el.current).getPropertyValue('padding-top'), 10) +
                        parseInt(window.getComputedStyle(el.current).getPropertyValue('padding-bottom'), 10),
                );
            }
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isOpened]);

    return (
        <Popup
            name={FEEDBACK_POPUP_NAME}
            className={classNames('feedback-popup', {
                'is-animating': isAnimating,
            })}
            overlay
        >
            <AnimatePresence>
                {isOpened && (
                    <m.div
                        className="feedback-popup__body"
                        initial={{ height: 0 }}
                        animate={{ height: popupHeight }}
                        exit={{ height: 0 }}
                        onAnimationComplete={() => {
                            setIsAnimating(false);
                        }}
                        onAnimationStart={() => {
                            setIsAnimating(false);
                        }}
                        transition={{
                            duration: 0.6,
                            ease: easeOutQuart,
                        }}
                    >
                        <m.div className="feedback-popup__hint" ref={hint}>
                            <Button
                                geometryVariant="square-bracket"
                                onClick={() => {
                                    closePopup(FEEDBACK_POPUP_NAME);
                                }}
                                aria-label="Закрыть форму обратной связи"
                                className="feedback-popup-close-button close-button"
                                ref={closeButton}
                            >
                                X
                            </Button>
                            <m.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ delay: 0.7, duration: 0.7 }}
                                className="hint-title m-text-xs"
                                dangerouslySetInnerHTML={{ __html: 'КАК РАБОТАЕТ НАШ СЕРВИС ПО&nbsp;ПОИСКУ?' }}
                            />
                            <m.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ delay: 0.75, duration: 0.7 }}
                                className="hint-description text-l"
                            >
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: 'Мы&nbsp;&mdash; одни из&nbsp;немногих в&nbsp;России, кто предоставляет гарантию на&nbsp;свои услуги.',
                                    }}
                                />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: 'Наша команда&nbsp;&mdash; это профессионалы в&nbsp;области поиска эксклюзивных изделий по&nbsp;лучшим ценам.',
                                    }}
                                />
                            </m.div>

                            <m.ul
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ delay: 0.8, duration: 0.7 }}
                                className="list-unstyled hint-list text-l"
                            >
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: `Отправьте скриншот изделия, которые Вы&nbsp;бы хотели заказать нашим персональным
                                    менеджерам в&nbsp;WhatsApp или Telegram. Вы&nbsp;не&nbsp;ограничены ассортиментом,
                                    представленным в&nbsp;нашем сайте!`,
                                    }}
                                />
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: `Далее менеджер произведёт уточнения нужных позиций для Вас с&nbsp;помощью наших
                                    источников в&nbsp;бутиках, либо у&nbsp;диллеров, которые работают в&nbsp;разных
                                    странах.`,
                                    }}
                                />
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: `Наш менеджер договаривается о&nbsp;сроках доставки и&nbsp;рассказывает Вам подробную
                                    информацию об&nbsp;изделии, после чего Вы&nbsp;можете забрать свой заказ у&nbsp;нас
                                    в&nbsp;офисе в&nbsp;Москве.`,
                                    }}
                                />
                            </m.ul>
                        </m.div>
                        <div className="feedback-popup__inner" ref={el}>
                            <AnimatePresence mode="wait">
                                {step === 'connect' && (
                                    <ConnectStep
                                        callback={(arg: StepName) => {
                                            setStep(arg);
                                        }}
                                    />
                                )}
                                {step === 'service' && (
                                    <ServiceStep
                                        callback={(arg: StepName) => {
                                            setStep(arg);
                                        }}
                                    />
                                )}
                                {step === 'form' && (
                                    <FormStep
                                        callback={(arg: StepName) => {
                                            setStep(arg);
                                        }}
                                    />
                                )}
                            </AnimatePresence>
                        </div>
                    </m.div>
                )}
            </AnimatePresence>
        </Popup>
    );
};

export default FeedbackPopup;
