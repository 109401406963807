import { CATEGORIES_IDS, CategoryId } from '@/api/categories';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

const ViewedContext = createContext<{
    activeCategoryId: CategoryId | 0;
    setActiveCategoryId: Dispatch<SetStateAction<CategoryId | 0>>;
    activeIndex: number;
    setActiveIndex: Dispatch<SetStateAction<number>>;
}>({
    activeCategoryId: CATEGORIES_IDS['watch'],
    setActiveCategoryId: () => {},
    activeIndex: 0,
    setActiveIndex: () => {},
});

export const useViewedContext = () => useContext(ViewedContext);

interface Props {
    children?: ReactNode;
}

const ViewedProvider = ({ children }: Props) => {
    const [activeCategoryId, setActiveCategoryId] = useState<CategoryId | 0>(0);
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <ViewedContext.Provider
            value={{
                activeCategoryId,
                setActiveCategoryId,
                activeIndex,
                setActiveIndex,
            }}
        >
            {children}
        </ViewedContext.Provider>
    );
};

export default ViewedProvider;
