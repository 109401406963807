import { ApiArticleTag } from '@/api/tags';
import Button from '../Button';
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState, createContext } from 'react';
import { useRouter } from 'next/router';
import { removeNullishAndUndefinedFromObject } from '@/utils/objects';
import { addUniqueItemToArray } from '@/utils/arrays/add-unique-item-to-array';
import classNames from 'classnames';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import BlogFiltersPopup, { BLOG_FILTERS_POPUP_NAME } from './BlogFiltersPopup';
import { useSetOpenedPopupsState } from '@/atoms/opened-popups';

interface Props {
    list: ApiArticleTag[];
    totalCount: number;
}

type CheckboxButtonProps = {
    children: ReactNode;
    checked: boolean;
    onChange: (arg: boolean) => void;
    value: string;
};

const TagsStateContext = createContext<{ tagsState: string[]; setTagsState: Dispatch<SetStateAction<string[]>> }>({
    tagsState: [],
    setTagsState: () => {},
});

export const useTagsStateContext = () => useContext(TagsStateContext);

const CheckboxButton = ({ children, checked, onChange, value }: CheckboxButtonProps) => {
    return (
        <label
            onClick={() => {
                onChange(!checked);
            }}
            className={classNames('checkbox-button', {
                'checkbox-button--checked': checked,
            })}
        >
            <input type="checkbox" name={value} value={value} defaultChecked={checked} />
            {children}
        </label>
    );
};

const BlogFilters = ({ list, totalCount }: Props) => {
    const router = useRouter();
    const [tagsState, setTagsState] = useState<string[]>([]);
    const [device] = useMediaQueryDeviceState();
    const { openPopup } = useSetOpenedPopupsState();
    const [portal, setPortal] = useState<Element>();

    useEffect(() => {
        const portalEl = document.querySelector<Element>('.js-blog-sort-popup-portal');
        if (portalEl) {
            setPortal(portalEl);
        }
    }, []);

    useEffect(() => {
        if (router.query['tags[]']) {
            if (Array.isArray(router.query['tags[]'])) {
                setTagsState(router.query['tags[]']);
            } else {
                setTagsState([router.query['tags[]']]);
            }
        } else {
            setTagsState([]);
        }
    }, [router]);

    return (
        <TagsStateContext.Provider value={{ tagsState, setTagsState }}>
            <div className="blog-tags">
                {device === 'desktop' ? (
                    <>
                        {list.length > 1 && (
                            <CheckboxButton
                                checked={tagsState.length === 0 || tagsState.length === list.length}
                                onChange={() => {
                                    router.push(
                                        {
                                            pathname: router.pathname,
                                            query: removeNullishAndUndefinedFromObject({
                                                ...router.query,
                                                'tags[]': [],
                                                page: 1,
                                            }),
                                        },
                                        undefined,
                                        { shallow: true },
                                    );
                                }}
                                value={'all'}
                            >
                                <Button geometryVariant="mustache">
                                    <div className="checkbox-button-inner">
                                        <span className="checkbox-button-inner__text">Все</span>
                                        <span className="checkbox-button-inner__count m-text-xs">{`[ ${totalCount} ]`}</span>
                                    </div>
                                </Button>
                            </CheckboxButton>
                        )}
                        {list.map((item) => {
                            return (
                                <CheckboxButton
                                    checked={tagsState.includes(item.id.toString())}
                                    key={item.id}
                                    onChange={(arg: boolean) => {
                                        router.push(
                                            {
                                                pathname: router.pathname,
                                                query: removeNullishAndUndefinedFromObject({
                                                    ...router.query,
                                                    'tags[]': arg
                                                        ? addUniqueItemToArray(tagsState, item.id.toString())
                                                        : tagsState.filter(
                                                              (prevItem) => prevItem !== item.id.toString(),
                                                          ),
                                                    page: 1,
                                                }),
                                            },
                                            undefined,
                                            { shallow: true },
                                        );
                                    }}
                                    value={item.slug}
                                >
                                    <Button geometryVariant="mustache">
                                        <div className="checkbox-button-inner">
                                            <span className="checkbox-button-inner__text">{item.name}</span>
                                            <span className="checkbox-button-inner__count m-text-xs">{`[ ${item.articles_count} ]`}</span>
                                        </div>
                                    </Button>
                                </CheckboxButton>
                            );
                        })}
                    </>
                ) : (
                    <>
                        <Button
                            variant="primary"
                            className="catalog-filter-btn blog-filter-btn"
                            size="sm"
                            onClick={() => {
                                openPopup(BLOG_FILTERS_POPUP_NAME);
                            }}
                        >
                            <span>фильтр</span>
                            {tagsState.length > 0 && (
                                <span className="blog-filters-count m-text-xs">{`[ ${tagsState.length} ]`}</span>
                            )}
                        </Button>
                        <BlogFiltersPopup tags={list} totalCount={totalCount} portal={portal} />
                    </>
                )}
            </div>
        </TagsStateContext.Provider>
    );
};

export default BlogFilters;
