import {
    Dispatch,
    HTMLAttributes,
    RefObject,
    SetStateAction,
    forwardRef,
    useContext,
    useEffect,
    useState,
    createContext,
    ReactNode,
} from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useOpenedPopupsValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';

export interface Props extends HTMLAttributes<HTMLElement> {
    containerRef?: RefObject<HTMLDivElement>;

    /**
     * Уникальное имя попапа
     */
    name: string;
    /**
     * Рендерить ли оверлей
     */
    overlay?: boolean;
    onOpen?: () => void;
    onOpenComplete?: () => void;
    onClose?: () => void;
    onCloseComplete?: () => void;
    wrapperSlot?: ReactNode;
    portalTarget?: Element | DocumentFragment;
}

const root = typeof window !== 'undefined' ? document.querySelector('#modal-root') : null;

const PopupContext = createContext<{ opened: boolean; setOpened: Dispatch<SetStateAction<boolean>> }>({
    opened: false,
    setOpened: () => {},
});

export const usePopupContext = () => useContext(PopupContext);

const Popup = forwardRef<HTMLDivElement, Props>(
    ({ wrapperSlot, children, containerRef, overlay = false, name, onOpen, onClose, portalTarget, ...props }, ref) => {
        const [opened, setOpened] = useState(false);
        const openedPopups = useOpenedPopupsValue();
        const { openPopup, closePopup } = useSetOpenedPopupsState();

        useEffect(() => {
            if (opened) {
                onOpen?.();
                openPopup(name);
            } else {
                onClose?.();
                closePopup(name);
            }
        }, [opened, name, closePopup, openPopup]);

        useEffect(() => {
            setOpened(openedPopups.includes(name));
        }, [openedPopups, name]);

        useEffect(() => {
            const onKeydown = (event: KeyboardEvent) => {
                if (event.code === 'Escape') {
                    setOpened(false);
                }
            };

            document.addEventListener('keydown', onKeydown);

            return () => document.removeEventListener('keydown', onKeydown);
        }, []);

        const onTouchMove = (event: Event) => {
            const eventTarget = event.target as HTMLElement;

            if (!eventTarget.closest('.js-popup-inner')) {
                event.stopPropagation();
            }
        };

        useEffect(() => {
            if (opened) {
                document.addEventListener('touchmove', onTouchMove);
            } else {
                document.removeEventListener('touchmove', onTouchMove);
            }

            return () => {
                document.removeEventListener('touchmove', onTouchMove);
            };
        });

        const Component = (
            <PopupContext.Provider value={{ opened, setOpened }}>
                <div
                    {...props}
                    ref={ref}
                    className={classNames(props.className, 'popup', { 'popup--opened': openedPopups.includes(name) })}
                >
                    {overlay && <div className="popup-overlay" onClick={() => setOpened(false)} />}
                    {wrapperSlot}
                    <div ref={containerRef} className="popup-container js-popup-inner">
                        {children}
                    </div>
                </div>
            </PopupContext.Provider>
        );

        return root ? createPortal(Component, portalTarget ? portalTarget : root) : Component;
    },
);

Popup.displayName = 'Popup';

export default Popup;
