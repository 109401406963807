import { ButtonHTMLAttributes, AnchorHTMLAttributes, forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';

export type Props = ButtonHTMLAttributes<HTMLButtonElement> &
    AnchorHTMLAttributes<HTMLAnchorElement> &
    Partial<LinkProps> & {
        /**
         * Какой тег рендерить
         */
        tag?: keyof JSX.IntrinsicElements | typeof Link;
        /**
         * Геометрия кнопки
         */
        geometryVariant?: 'default' | 'wide' | 'mustache' | 'icon' | 'large' | 'square-bracket' | 'arrow';
        /**
         * Цветовая тема кнопки
         */
        variant?: 'default' | 'light' | 'dark' | 'bordered' | 'bordered-light' | 'primary' | 'bordered-light-bg-dark';
        /**
         * Иконка в кнопке
         */
        icon?: ReactNode;
        /**
         * Позиция иконки
         */
        iconReverse?: boolean;
        /**
         * Размер кнопки
         */
        size?: 'default' | 'sm' | 'md' | 'lg';
        visuallyDisabled?: boolean;
        textClass?: string;
    };

const Button = forwardRef<HTMLButtonElement, Props>(
    (
        {
            children,
            variant = 'default',
            tag = 'button',
            icon,
            geometryVariant = 'default',
            size = 'default',
            onClick,
            iconReverse = false,
            visuallyDisabled = false,
            textClass,
            ...props
        },
        ref,
    ) => {
        const Component = tag as any;
        const isPhone = props.itemProp === 'telephone';
        return (
            <Component
                type={tag === 'button' ? 'button' : undefined}
                {...props}
                ref={ref}
                onClick={onClick}
                className={classNames(
                    props.className,
                    'btn',
                    `btn-${variant}`,
                    `btn-geometry-${geometryVariant}`,
                    `btn-${size}`,
                    {
                        'btn-icon-reverse': iconReverse,
                    },
                    {
                        'btn-visually-disabled': visuallyDisabled,
                    },
                )}
            >
                {geometryVariant === 'mustache' ? <span className="mustache mustache-left"></span> : null}
                {geometryVariant === 'large' ? <span className="hat hat-top" /> : null}
                {geometryVariant === 'square-bracket' ? (
                    <span className="square-bracket square-bracket--left">{'['}</span>
                ) : null}

                <span className="btn__outer">
                    {icon && geometryVariant === 'arrow' && iconReverse && (
                        <span className="btn__icon btn__icon-arrow btn__icon-arrow-reversed">{icon}</span>
                    )}
                    <span className="btn__inner">
                        {children ? (
                            <span
                                className={classNames('btn__text', textClass)}
                                itemProp={isPhone ? 'telephone' : undefined}
                            >
                                {children}
                            </span>
                        ) : null}
                        {icon && geometryVariant !== 'arrow' ? <span className="btn__icon">{icon}</span> : null}
                    </span>
                    <span className="btn__copy">
                        {children ? <span className={classNames('btn__text', textClass)}>{children}</span> : null}
                        {icon && geometryVariant !== 'arrow' ? <span className="btn__icon">{icon}</span> : null}
                    </span>
                    {icon && geometryVariant === 'arrow' && !iconReverse && (
                        <span className="btn__icon btn__icon-arrow btn__icon-arrow-not-reversed">{icon}</span>
                    )}
                </span>

                {geometryVariant === 'mustache' ? <span className="mustache mustache-right"></span> : null}
                {geometryVariant === 'large' ? <span className="hat hat-bottom" /> : null}
                {geometryVariant === 'square-bracket' ? (
                    <span className="square-bracket square-bracket--right">{']'}</span>
                ) : null}
            </Component>
        );
    },
);

Button.displayName = 'Button';

export default Button;
